<template>
  <loading v-if="isLoading" />
  <error v-else-if="isError" />
  <content-not-view v-else-if="!subPermission.index" />

  <div v-else>
    <b-card>
      <div class="custom-search d-flex justify-content-end mb-2">
        <b-button
          :to="{
            name: 'ViewServiceRequest',
            params: { id: currentId },
          }"
          variant="primary"
          >{{ $t("g.backToServiceRequest") }}</b-button
        >
      </div>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <span
            v-if="props.column.label === 'isCertRequestAccepted'"
            class="text-nowrap"
          >
            {{ $t("g.requestToIssueACertificate") }}
          </span>
          <span
            v-else-if="props.column.label === 'isComercialRecordAccepted'"
            class="text-nowrap"
          >
            {{ $t("g.commercialRegisterDocument") }}
          </span>
          <span
            v-else-if="props.column.label === 'isFactoryAccepted'"
            class="text-nowrap"
          >
            {{ $t("g.factoryDocument") }}
          </span>
          <span
            v-else-if="props.column.label === 'isShippingAccepted'"
            class="text-nowrap"
          >
            {{ $t("g.shippingDocument") }}
          </span>
          <span
            v-else-if="props.column.label === 'isSupplyingAccepted'"
            class="text-nowrap"
          >
            {{ $t("g.supplierDocument") }}
          </span>
          <span
            v-else-if="props.column.label === 'submitDate'"
            class="text-nowrap"
          >
            {{ $t("g.submitDate") }}
          </span>
          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'is_cert_request_accepted_row'"
            class="text-nowrap"
          >
            {{
              props.row.is_cert_request_accepted
                ? $t("g.acception")
                : $t("g.rejection")
            }}
          </span>

          <span
            v-else-if="
              props.column.field === 'is_comercial_record_accepted_row'
            "
            class="text-nowrap"
          >
            {{
              props.row.is_comercial_record_accepted
                ? $t("g.acception")
                : $t("g.rejection")
            }}
          </span>

          <span
            v-else-if="props.column.field === 'is_factory_accepted_row'"
            class="text-nowrap"
          >
            {{
              props.row.is_factory_accepted
                ? $t("g.acception")
                : $t("g.rejection")
            }}
          </span>

          <span
            v-else-if="props.column.field === 'is_shipping_accepted_row'"
            class="text-nowrap"
          >
            {{
              props.row.is_shipping_accepted
                ? $t("g.acception")
                : $t("g.rejection")
            }}
          </span>

          <span
            v-else-if="props.column.field === 'is_supplying_accepted_row'"
            class="text-nowrap"
          >
            {{
              props.row.is_supplying_accepted
                ? $t("g.acception")
                : $t("g.rejection")
            }}
          </span>
          <span
            v-if="props.column.field === 'submit_date_row'"
            class="text-nowrap"
          >
            {{ format(props.row.submit_date) }}
          </span>

          <span v-if="props.column.field === 'action'">
            <span>
              <b-avatar
                size="32"
                variant="light-primary"
                :to="{
                  name: 'ReviewRequestsList',
                  params: { id: props.row.id },
                }"
              >
                <feather-icon icon="EyeIcon" size="18" />
              </b-avatar>
              <!-- <b-avatar class="mr-1 ml-1" size="32" variant="light-success">
                <feather-icon icon="EditIcon" size="18" />
              </b-avatar>
              <b-avatar size="32" variant="light-danger">
                <feather-icon icon="Trash2Icon" size="18" />
              </b-avatar> -->
            </span>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText2") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import formatDate from "@/composables/format-date/format-date";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    Loading,
    Error,
    ContentNotView,
    BButton,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "isCertRequestAccepted",
          field: "is_cert_request_accepted_row",
        },
        {
          label: "isComercialRecordAccepted",
          field: "is_comercial_record_accepted_row",
        },
        {
          label: "isFactoryAccepted",
          field: "is_factory_accepted_row",
        },
        {
          label: "isShippingAccepted",
          field: "is_shipping_accepted_row",
        },
        {
          label: "isSupplyingAccepted",
          field: "is_supplying_accepted_row",
        },
        {
          label: "submitDate",
          field: "submit_date_row",
        },
        /* {
          label: "action",
          field: "action",
        }, */
      ],
      rows: [],
      searchTerm: "",
      currentId: null,
      format: null,
      subPermission: {},
      isLoading: true,
      isError: false,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  beforeMount() {
    this.format = formatDate;
    this.currentId = this.$route.params.id;
    this.$http
      .get(`admin/serviceRequests/${this.currentId}/reviews`)
      .then((res) => {
        
        this.rows = res.data.data;
        setTimeout(() => {
          this.isLoading = false;
          this.isError = false;
          store.dispatch("GET_PERMISSION", "reviews");
          this.subPermission = store.state.permissions.sub;
        }, 1000);
      })
      .catch((error) => {
        this.isLoading = false;
        this.isError = true;
        console.log(error.message);
        window.location;
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
